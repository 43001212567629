.container {
    padding: 1rem 2rem !important;

    @media (max-width: 540px) {
        .content-container .infinite-scroll-component__outerdiv .infinite-scroll-component {
            margin-left: calc(100vw / 6) !important;
            .card {
                width: 80% !important;
                img {
                    width: 100% !important;
                    height: 10rem !important;
                }
            }
        }  
    }

    @media (min-width: 540px) and (max-width: 860px) {
        .content-container .infinite-scroll-component__outerdiv .infinite-scroll-component {
            .card {
                img {
                    height: 12rem !important;
                }
            }
        }  
    }
    .content-container .infinite-scroll-component__outerdiv .infinite-scroll-component  {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.25rem;
        justify-content: flex-start;

        .card  {
            width: 23%;
            margin-left: 1%;
            margin-right: 1%;
            padding: 1rem;
            display: inline-block;
            margin-bottom: 1.25rem;
            border: 1px solid white;
            border-radius: 6px !important;

            img {
                width: 100%;
                display: block;
                border-radius: 6px;
                height: 16rem;
            }
            .card-body {
                cursor: pointer;
                color: black !important;
                padding: 0.65rem 0.5rem 0 0 !important;

                .card-title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 0 !important;
                    margin-top: 0.75rem;
                    font-size: 0.95rem;
                    color: #212529 !important;
                }
            }

            .no-record {
                height: 5rem;
                background: #051641;
                color: grey;
                font-size: 1rem;
            }
        }
    }

    .content-container .infinite-scroll-component__outerdiv .infinite-scroll-component .card:hover {
        box-shadow: #adb5bd 0px 13px 27px -5px, #0dcaf0 0px 8px 16px -8px
    }
}
.stateDiv {
    display: flex;
    padding-top: 2%;
    .backState {
        cursor: pointer;
        
    }
}