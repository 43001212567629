.container {
    margin-top: 0 !important;
    padding: 1rem 0rem !important;
    padding-top: 4.5rem !important;
    position: relative;

    .videos-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 2rem 0 0 !important;

        /* If screen size is more than 768px wide, set height to 18rem */
        @media screen  and (min-width: 768px){
            .card {
                height: 16rem;
            }
        }

        /* If screen size is more than 1080px wide, set height to 18rem */
        @media screen  and (min-width: 1080px){
            .card {
                height: 21rem;
            }
        }
        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
          
        .card  {
            width: 23%;
            margin-right: 1%;
            margin-left: 1%;
            padding: 1rem;
            display: inline-block;
            margin-bottom: 1rem;
            border: 1px solid white;
            border-radius: 6px !important;

            img {
                width: 100%;
                display: block;
                border-radius: 6px;
                height: 16rem;
            }
            .card-body {
                cursor: pointer;
                color: black !important;
                padding: 0.65rem 0.5rem 0 0 !important;

                .card-title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 0 !important;
                    font-size: 0.95rem;
                    color: #212529 !important;
                }
                .card-subtitle .likes {
                    display: flex;
                    svg {
                        color: green;
                        font-size: 1.25rem;
                        margin-top: 0.5rem;
                    }

                    .likes-count {
                        margin-top: 0.4rem;
                        padding-left: 0.5rem;
                    }
                }
            }

            .no-record {
                height: 5rem;
                background: #051641;
                color: grey;
                font-size: 1rem;
            }
        }

        .card:hover {
            box-shadow: #adb5bd 0px 13px 27px -5px, #0dcaf0 0px 8px 16px -8px;
        }
    }
}

.cardStyle {
    display: flex;
    justify-content: space-between;
    margin: 0% !important;
    padding: 0% !important;
}