.search-container {
    display: flex;
    align-items: center;
    width: 40% !important;
    border: none;
    border-bottom: 1px solid white;

    svg {
        color: grey;
        font-size: 1.5rem;
        margin-left: 0.5rem;
    }

    input {
        border: none;
        color: grey;
        background: transparent;
        padding: .375rem .5rem;
    }

    input:focus {
        color: grey;
        box-shadow: none;
        background: transparent;
        border: none !important;
    }

    input::placeholder {
        color: grey;
    }
}

@media (max-width: 360px) {
    .search-container {
        width: 25% !important;
    }
}
