.react-player {
    width: auto !important;
}

.videoBtn {
    height:40px;
    width: 40px;
    background-color: transparent;
    font-size: 15px;
    z-index: 100;
    border-radius: 50% !important;
}
.bottom-bar {
    display: flex;
    flex-direction: column;
    .progress-slider {
        width: 100%;
    }
    .bottom-control {
        margin: 0%;
        display: flex;
        align-items: baseline;
    }
    .playPause {
        margin-left: 1%;
        margin-right: 2%;
        height: 10%;
    }
    .muteUnmute {
        height: 25%;
        margin-right: 1%;
    }
    .volumeSlide {
        height: 10px;
        bottom: 2px;
        width: 11%;
        background-color: aqua;
    }
}


