@import '~primeicons/primeicons.css';

.container {
    padding-top: 1rem !important;

    .container {
        padding: 0 !important;
        margin-top: 1.5rem !important;
    }

    .nav.nav-tabs {
        padding-top: 2rem !important;
        border-bottom: 1px solid #8080807d;
        .nav-item {
            cursor: pointer;
            .nav-link {
                color: black;
                border: 1px solid #6a6f74;
                border-top-left-radius: 0.375rem;
                border-top-right-radius: 0.375rem;
            }
            .active.nav-link {
                color: #ffff;
                border-color: #6c757d;
                background-color: #6c757d;
            }
        }
    }

    .category-selection {
        display: flex;
        color: #ffff;
        margin: 1.5rem 0.5rem;

        .title {
            color: black;
            padding-top: 0.4rem;
            padding-right: 0.5rem;
        }

        select {
            width: auto;
        }
    }

    .available-category {
        display: flex;
        max-width: 32rem;
        min-width: 18rem;
        padding-top: 1rem;
        flex-direction: column;

        .category-list {
            overflow: auto;
            color: black;
            border: 0.05rem solid grey;
            max-height: calc(100vh - 16rem);


            div {
                padding: 0.5rem;
                border-bottom: 0.05rem solid grey;

                svg {
                    color: red;
                    cursor: pointer;
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .add-category {
        width: 50%;
        margin: auto;
        display: flex;
        max-width: 25rem;
        min-width: 18rem;
        padding-top: 1rem;
        flex-direction: column;

        .add-action {
            width: 5rem;
            margin: auto;
            margin-top: 1rem;
        }

        input {
            font-size: 0.85rem;
            border-color: #d6e1f5;
            color: black !important;
            background-color: #e8f0fe !important;
        }

        btn {
            font-size: 0.85rem;
            border-radius: 6px;
            padding: 0.45rem 0.98rem;
        }
    }

    h5 {
        color: black !important;
        margin-bottom: 0.75rem !important;
    }
}

.modal .modal-dialog.confirmation .modal-content {
    .modal-body {
        padding: 1.5rem 1.5rem 1.5rem;
    }

    .modal-footer {
        border: none;
        padding-top: 0;

        .btn {
            font-size: 0.75rem;
            border-radius: 6px;
            padding: 0.45rem 0.98rem;
        }
    }

    .modal-header {
        display: none;
    }
}

@media (max-width: 740px) {
    .container .container .row {    
        flex-direction: column-reverse;

        .col-5,
        .col-7 {
            width: auto;
            display: flex;
            justify-content: center;
        }
    }
}
