.old-comments {
    display: flex;
    padding-top: 0.75rem;

    .avator-comments {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 1rem;
        margin-top: 0.25rem;

        svg {
            border-radius: 50%;
        }
    }

    .content {
        font-size: 0.85rem;

        .date {
            color: grey;
        }
    }
}
