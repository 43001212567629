.container.my-5 {
    max-width: unset !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    min-height: unset !important;

    footer {
        .container {
            margin: 0;
            padding: 0 2rem !important;
            max-width: unset !important;
            background: black;

            .row {

                .col-lg-3,
                .col-lg-6 {
                    padding-top: 2rem !important;
                }
            }
        }
    }
}

footer {
    .container.p-4 {
        min-height: unset !important;

        .row {
            .logo {
                width: 10rem;
            }

            h6 {
                color: #ffff;
            }

            color: gray;
            font-size: 0.786rem;

            .mb-4 {
                margin-bottom: 0 !important;
            }
        }
    }
}
