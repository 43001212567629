.modal {
  .details-modal {
    width: 90vw;
    overflow: auto;
    margin: 3% auto;
    max-width: 70rem;

    .modal-content {
      height: 90vh !important;
    }

    .modal-header {
      border: none;
      height: 5rem;
      padding-left: 1.85rem;
      padding-right: 1.85rem;
      background: #7f7f7f0a;
    }

    .modal-body {
      background: #7f7f7f0a;
      padding: 0 1rem 0 1.85rem;
      padding-bottom: 0px !important;
      overflow: auto;
      .video-details {
        display: flex;
        .video-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          .videoPlayer {
            height: 56vh;
            border: 1px solid #8080802e;
          }
          .title {
            font-weight: 500;
            font-size: 1.65rem;
            padding-top: 0.5rem;
          }
          //.category,
          //.category-container,
          .description,
          .description-box{
            background-color: #efeeee; /* Light gray background */
            border: 2px solid #efeeee; /* Red border */
            border-radius: 5px; /* Optional: adds rounded corners */
            //box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
            padding: 10px; /* Add padding around the section */
          }
          .category {
            font-weight: 500;
            font-size: 0.85rem;
            margin: 0.25rem 0;
            border-radius: 4px; 
            width: fit-content;
            padding: 0.25rem 0.5rem;
            border-color: #d7d9d9 !important;
            background: rgba(216, 217, 217, 0.859) !important;
          }
          .category-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;
            .author-details {
              display: flex;
              #author-avator {
                width: 2.5rem;
                svg {
                  border-radius: 50%;
                }
              }
              .user-details {
                display: flex;
                padding-left: 0.5rem;
                flex-direction: column;
                justify-content: center;
                .name {
                  font-size: 1rem;
                  text-transform: capitalize;
                }
                .date {
                  color: grey;
                  font-size: 0.75rem;
                }
              }
            }

            .video-analytics { 
              display: flex;
              .icon {
                display: flex;
                cursor: pointer;
                svg {
                  color: green;
                  font-size: 1.25rem;
                  margin-top: 0.5rem;
                  margin-right: 5px;
                }
                .likes-count {
                  margin-top: 0.4rem;
                  padding-left: 0.25rem;
                  margin-right: 0.5rem;
                }
                .view-count {
                  margin-top: 0.4rem;
                  padding-left: 0.25rem;
                }
              }
            }
          }
          .description {
            background-color: #d0312d;
            color: #141619bd;
            overflow: auto;
            font-size: 0.85rem;
          }

          .description-box .researchId {
            color: #343a40;
            font-size: 0.84rem;
            b {
              font-weight: 500;
              white-space: no-wrap;
              padding-right: 0.25rem;
            }
          }

          .review {
            display: flex;
            justify-content: end;
            height: 1.5rem;

            button {
              display: flex;
              font-size: 0.85rem;
              align-items: center;
              padding: 0.15rem 0.5rem;
              background: transparent;

              svg {
                margin-right: 0.25rem;
              }
            }

            .accept {
              color: green;
              border-color: green;
              padding: 0.15rem 0.5rem;
              margin-right: .25rem;;
            }

            .reject {
              color: red;
              border-color: red;
              margin-left: 0;
            }
          }
        }
        .other {
          width: 50%;
          padding-left: 3rem;
        }

        .delete-button-container {
          position: absolute;
          bottom: 24px;
          right: 24px;

          color: white;
          border-color: #d0312d;
          border-radius: 50%;
          background: #d0312d;
        }
      }
    }

    .error {
      color: red;
      margin-top: 15%;
      text-align: center;
      svg {
        font-size: 4rem;
      }
      .message {
        padding-top: 0.5rem;
      }
    }
  .icon {
    display: flex;
    align-items: center;
  }
    
  .share {
    //margin-left: 6px; /* Adjust the margin as needed */
    margin-top: 6px; /* Add margin-top to push the text down */
    margin-right: 10px; 
  }
  }
}

@media (max-width: 560px) {
  .modal .details-modal .modal-body .video-details {
    flex-direction: column;

    .other {
      width: 100%;
      padding-left: 0;      
    }

    .video-container {
      height: auto !important;
    }
  }
}
