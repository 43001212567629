.container {
    margin-top: 0 !important;
    padding: 1rem 0rem !important;
    padding-top: 1.5rem !important;

    .nav.nav-tabs {
        padding-top: 1rem;
        margin-bottom: 1rem !important;
        border-bottom: 1px solid #8080807d;
        .nav-item {
            cursor: pointer;
            .nav-link {
                color: black;
                border: 1px solid #6a6f74;
                border-top-left-radius: 0.375rem;
                border-top-right-radius: 0.375rem;
            }
            .active.nav-link {
                color: #ffff;
                border-color: #6c757d;
                background-color: #6c757d;
            }
        }
    }

    .videos-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 2rem 0 0 !important;

        @media (max-width: 540px) {
            .card {
                width: 80% !important;
                img {
                    height: 10rem !important;
                }

            }
        }

        @media (min-width: 540px) and (max-width: 860px) {
            .card {
                img {
                    height: 12rem !important;
                }
            }
        }

        .card  {
            width: 23%;
            height: auto;
            padding: 1rem;
            margin-left: 1%;
            margin-right: 1%;
            display: inline-block;
            margin-bottom: 1.25rem;
            border: 1px solid white;
            border-radius: 6px !important;

            img {
                width: 100%;
                display: block;
                border-radius: 6px;
                height: 16rem;
            }
            .card-body {
                cursor: pointer;
                color: black !important;
                padding: 0.65rem 0.5rem 0 0 !important;

                .card-title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: 0 !important;
                    margin-top: 0.75rem;
                    font-size: 0.95rem;
                    color: #212529 !important;
                }
                .card-subtitle .likes {
                    display: flex;
                    svg {
                        color: green;
                        font-size: 1.25rem;
                        margin-top: 0.5rem;
                    }

                    .likes-count {
                        margin-top: 0.4rem;
                        padding-left: 0.5rem;
                    }
                }
            }

            .no-record {
                height: 5rem;
                background: #051641;
                color: grey;
                font-size: 1rem;
            }
        }

        .card:hover {
            box-shadow: #adb5bd 0px 13px 27px -5px, #0dcaf0 0px 8px 16px -8px;
        }
    }
}

@media (max-width: 540px) {
    .container {
        .videos-container {
            justify-content: center !important;
        }

        .nav.nav-tabs {
            padding: 0 0.5rem;
        }
    }
}
