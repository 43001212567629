.home.container {
    max-width: 95vw !important;
    padding-top: 1.5rem !important;

    .category {
        padding: 0 1rem;
        margin-bottom: 2.5rem;
        h6 {
            color: rgb(255, 255, 255);
            margin-bottom: 0;
            background-color: rgb(34, 31, 31); /* Add your desired background color */
            border: 2px solid rgb(34, 31, 31); /* Add your desired border style */
            border-radius: 5px; /* Optional: adds rounded corners */
            padding: 10px; /* Add padding around the section */
        }
        .category-list .slick-slider {
            .slick-next:before, .slick-prev:before {
                color: #0d6efd;
            }
            .slick-list {
                padding: 0.85rem 1rem 1rem 1rem;
            }
        }

        .category-list .slick-slider {
            @media (max-width: 680px) {
                .slick-list {
                    padding-left: 0 !important;
                    padding-bottom: 0 !important;
                }
            }
        }
        .category-list .slick-slider .slick-list .slick-track {
            @media (max-width: 680px) {
                .slick-slide {
                    width: 10rem !important;

                    .card {
                        height: 12rem !important;
                        padding: 0.5rem !important;

                        img {
                            height: 8rem !important;
                        }
                        
                        .card-style.card-body {
                            .card-title {
                                margin-top: 0 !important;
                                font-size: 0.75rem !important;
                            }

                            .cardStyle {
                                h9 {
                                    margin-top: 0 !important;
                                    font-size: 0.75rem !important;
                                }
                            }
                        }
                    }
                }
            }
            
            @media (min-width: 680px){
                .slick-slide {
                    width: 18rem !important;
                }
            }
            .slick-slide {
                cursor: pointer;
                // width: 18rem !important;
                margin-right: 1rem !important;

                div .load-more {
                    border: none;
                    margin-top: 50%;
                    margin: 54% 2rem;
                    border-radius: 4px;
                    display: inline-block;
                    background: #4b90f9;
                    width: fit-content !important;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

                    svg {
                        color: white;
                        font-size: 1.25rem;
                        margin-left: 0.65rem;
                    }

                    .btn {
                        border: none;
                        font-size: 0.85rem;
                        background: #4b90f9;
                        padding-left: 0.45rem;
                    }

                }

                div .card  {
                    border: 1px solid white;
                    width: 100%;
                    padding: 1rem;
                    display: inline-block;
                    border-radius: 6px !important;

                    img {
                        height: 16rem;
                        width: inherit;
                        border-radius: 6px;
                    }
                    .card-body {
                        cursor: pointer;
                        color: black !important;
                        padding: 0.65rem 0.5rem 0 0 !important;

                        .card-title {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-bottom: 0 !important;
                            margin-top: 0.75rem;
                            font-size: 0.95rem;
                            color: #212529 !important;
                        }
                    }
                }

                .no-record {
                    color: grey;
                    background: transparent;
                    font-size: 1rem;
                }

                div .card:hover {
                    box-shadow: #adb5bd 0px 13px 27px -5px, #0dcaf0 0px 8px 16px -8px
                }
            }
        }
    }
}

.cardStyle {
    display: flex;
    justify-content: space-between;
    margin: 0%;
}
