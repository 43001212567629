.navbar {
    padding: 0 2.5rem !important;
    background-image: linear-gradient(to right, #000000 0%, black 100%);
    box-shadow: rgb(0, 0, 0) 0px 2px 1px, rgba(0, 0, 0, 0) 0px 4px 2px, rgba(0, 0, 0, 0) 0px 8px 4px, rgba(0, 0, 0, 0) 0px 16px 8px, rgba(0, 0, 0, 0) 0px 32px 16px;
    
    .navbar-brand {
        img {
            height: 2.5rem;
        }
    }

    .navbar-toggler {
        .custom-toggle-icon {
            color: #ffff;
        }
    }

    .collapse.navbar-collapse.show {
        flex-basis: 100% !important;
    }
    .collapse.navbar-collapse {
        flex-grow: unset;
        flex-basis: unset;

        .navbar-nav {
            .nav-item {
                font-size: 0.85rem;
                padding-left: 0.45rem;

                .nav-link a {
                    color: #ffff;
                    padding: 0.4rem;
                    text-decoration: none;

                    svg {
                        font-size: 1.25rem;
                        margin-right: 0.25rem;
                        margin-bottom: 0.16rem;
                    }
                }
            }

            .user-details-minview {
                display: flex;
                color: #fff;
                align-items: center;
                flex-direction: column;
                margin: 0.25rem 0;
                padding-bottom: 0.25rem;
                border-bottom: 1px solid #ffff;

                #avatar-element {
                    svg {
                        width: 4rem;
                        height: 4rem;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        justify-content: space-between !important;
    }
}

@media (min-width: 680px) {
    .navbar-expand-lg .navbar-toggler {
        display: none !important;
    }
}

@media (min-width: 680px) {
    .navbar-expand-lg .navbar-collapse {
        flex-basis: auto;
        display: flex !important;
        flex-direction: row-reverse !important;
    
        .navbar-nav {
            flex-direction: row !important;
            align-items: baseline !important;

            .nav-item .nav-link .user-dropdown.dropdown {
                border-radius: 50%;
                width: 2.5rem !important;
                height: 2.5rem !important;
    
                .btn.btn-secondary {
                    width: 2.5rem;
                    height: 2.5rem;
                    padding: 0rem !important;
                    border-radius: 51% !important;  
                    
                    #avatar-element {
                        svg {
                            border-radius: 50%;
                        }
                    }
                }
    
                .dropdown-menu.show {
                    width: 15rem !important;
                    .user-details {
                        display: flex;
                        margin: 0 0.5rem;
                        align-items: center;
                        flex-direction: column;
                        border-bottom: 1px solid #80808042;
    
                        #avatar-element-drop-down {
                            width: 5rem;
                            height: 5rem;
                            margin: 1.5rem 0 0;
    
                            svg {
                                border-radius: 50%;
                            }
                        }
    
                        .email {
                            font-weight: 500;
                            padding: 0.4rem 0;
                            font-size: 0.85rem;
                        }
                    }
    
                    button a {
                        color: black;
                        font-weight: 500;
                        font-size: 0.85rem;
                        svg {
                            color: black;
                            margin-right: 0.58rem;
                        }
                    }
                    .dropdown-item {
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
